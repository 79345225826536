jQuery(document).ready(function () {
  jQuery("#services-accordian .service-desc").on(
    "show.bs.collapse",
    function () {
      var id = jQuery(this).attr("data-service-id");

      // Hide all asides
      jQuery(".card-aside").removeClass('inplace');

      // Show this aside
      jQuery("#card-aside-" + id).addClass('inplace');

      var element_id = jQuery(this).attr("id");

      if (jQuery(document).width() <= 991) {
        setTimeout(function () {
          jQuery("html, body").animate(
            {
              scrollTop: jQuery("#" + id + "-button").offset().top - 10,
            },
            200
          );

          // Close headroom
          setTimeout(function () {
            headroom.unpin();
          }, 250);
        }, 300);
      }
    }
  );
});
